import React, { useState, useEffect } from "react";

function HeroContact() {
    return (
        <section className="relative">
            <div className="max-w-6xl mx-auto px-4 sm:px-6">
                <div className="pb-12 md:pb-20">

                </div>
            </div >
        </section >
    );
}

export default HeroContact;
