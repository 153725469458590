import React from 'react';
import './style.css';
import Header from "../../partials/Header";
const TermsConditions = () => {
    return (
        <>
            <Header />
            <div className="ng-scope">

                <main className="generic-body ng-scope">

                    <div style={{ marginTop: 50, marginLeft: 40, width: '80%' }}>
                        <div>
                            <image />
                            <h2 className="generic-body__title">Terms and Conditions</h2>
                        </div>
                        <p style={{ color: 'black' }}>
                            These Terms and Conditions apply to your access to and use of TEDDict (the <strong> “Application” </strong>,<strong> “us” </strong>,
                            <strong>“our”</strong>, or <strong>“we”</strong>),
                            the official mobile and software application and other portal owned, operated, branded or made available by TEDDict
                            which is engaged in the service of online tutoring of design, creation and other activities including inventing and
                            critical thinking to children.
                            These Terms and Conditions (“Terms”) relates to the software application that allows you to access and use the Application,
                            services, software and products (hereinafter collectively referred to as the “Services”), whereas, “you”, “your”, or “user”
                            would mean the person using our Services or Application.
                            Please read these Terms and Conditions carefully. By accessing or using the Services and Application, or by clicking ‘I Agree’
                            you agree to be bound by these Terms and Conditions. If you do not consent to such terms, you are not permitted to use our Services
                            and Application. These Services are not meant to be used by anyone under the age of 18 years without the express permission of
                            their parent or legal guardian. The parents or the legal guarding of the child who provide consent for child’s use of this Application,
                            are also assumed to be bound by these terms in respect of their use of the Application. If you do no agree to Terms of this agreement to
                            be legally bound by the Terms set forth herein. If you do not agree to the Terms of this agreement, you can exit this page and stop
                            accessing the Services.
                        </p>
                        <p style={{ color: 'black' }}>
                            We reserve the right to change, modify, revise or amend any provision of the Terms and Conditions, and any other terms,
                            policies or guidelines governing your use of the Services, at any time at our sole discretion by providing you a written
                            notice or mail that the Terms and Condition has been modified. If you do not agree with such modification, you may notify
                            us in writing through the mail and stop using our Service within thirty (30) days of such notification.
                            You authorise us to collect, use, disclose and otherwise process your information as set forth in our PRIVACY POLICY
                            to track your behaviour and suggest optimal Services. You may delete or remove such personal data from our Application by going to
                            the setting menu or alternatively, you may <a href="mailto:help@teddict.com " _blank >contact us</a> through email.

                            These Terms and Conditions along with the Privacy Policy and the following additional rules and policies (as applicable)
                            (hereinafter referred to as “Terms”) together constitute a binding agreement between you and TEDDict. If TEDDict has posted
                            or provided a translation of the English language version of the Terms, you agree that the translation is provided for
                            convenience only and that the English language version will govern your access to and use of the Services or the Application.
                        </p>

                        <p style={{ color: 'black' }}>

                            <h3>
                                Services Description:
                            </h3>
                            TEDDict facilitates online learning services to children. We aim at providing lessons on helping children groom their new skill,
                            be creative, explore new ideas and bring out their hidden talent by making new inventions. The concept is to create a virtual classNameroom,
                            which helps the child to learn without any restrictions on time and place. Where the child can easily upload his/her work through pictures,
                            audio or video and get feedback from the experts. Each child will be given a badge as a reward for their task.

                        </p>
                        <p style={{ color: 'black' }}>
                            <h3>
                                User ID and Password: {" "}
                            </h3>
                            In order to access the Application and its Services, you may have to create an account and disclose information including, but not limited to,
                            <li>Name, e-mail address, photograph, location and other contact information,</li>
                            <li>Gender and other demographics,</li>
                            <li>Birth date and year to validate the current age of the child,</li>
                            <li>E-mail address to verify parental consent,</li>

                            <p style={{ color: 'black' }}>
                                <h3>
                                    Acconut Terms:
                                </h3>
                                <li>
                                    You acknowledge that your user ID and password (“Account”) is for your exclusive use only.
                                </li>
                                <li>
                                    Use or sharing of your Account with another user or person is not permitted and is cause for immediate blocking of your access to this Application,
                                    the Services and the content provided by the TEDDict and shall lead to termination of this Agreement without any notice.
                                </li>
                                <li>
                                    You are solely responsible for maintaining the confidentiality of your Account and for all activities that occur under it.
                                </li>
                                <li>
                                    You agree to immediately notify TEDDict if you become aware of or have reason to believe that there is any unauthorised use of your Account
                                </li>
                                <li>
                                    You also agree to take all reasonable steps to stop such unauthorized use and to cooperate with TEDDict in any investigation of such unauthorized uses.
                                </li>

                                TEDDict shall not under any circumstances be held liable for any claims related to the use or misuse of your Account due to the activities of any third party
                                outside of your control or due to your failure to maintain the confidentiality and security of your Account.
                            </p>
                            <strong>
                                Note:
                            </strong>
                            Children below the age of 18 years are not permitted to register on this Application without parent or guardian consent.
                        </p>

                        <p style={{ color: 'black' }}>
                            <h3>
                                Trials
                            </h3>
                            We offer one (1) free trial className to our new members so that you get an opportunity to experience the Services provided by us.
                            Only one (1) free trial className is permitted per new member.
                            Multiple enrolment of trial classNamees by an individual either through his/her own account or through someone else’s accounts, email
                            IDs and/or in any other manner whatsoever, without the prior written permission of TEDDict is not allowed and shall amount to a
                            breach of the present Terms.
                            Any free trial className provided by us shall also be governed by these Terms.
                        </p>
                        <p style={{ color: 'black' }}>
                            <h3>
                                Modules and Curriculum

                            </h3>
                            The Application will have its sets of modules according to which the child is expected to complete his/her levels and achieve badges.
                            The Application shall also grant you access to its materials, content, curriculum, documents and other information and data (“Curriculum”)
                            which may be in video, audio, written, graphic, recorded, photographic, or any other format in relation to the modules for which you have registered for.
                            TEDDict reserves the right to amend, revise or update the Curriculum at any time.

                        </p>
                        <p style={{ color: 'black' }}>
                            <h3>
                                License to Use
                            </h3>
                            TEDDict hereby grants you, the limited, non-transferable, non-exclusive and revocable license to access, view and use the Application only for the purposes
                            of accessing, viewing, posting or submitting user content, using the embedded link function, placing store orders or for accessing information, applications and services.
                            TEDDict reserves the right to suspend or deny, in its sole discretion, your access to all or any portion of the Application.
                            This license is limited to personal and non-commercial uses by you. Any rights not expressly granted to you herein are reserved to TEDDict.
                            Your are not permitted to reproduce, transmit, distribute, sub-license, broadcast, disseminate or prepare derivative works of the Curriculum, or any part thereof, in any
                            manner or through any communication channels or means, for any purpose other than the limited purpose mentioned above, without the TEDDict’s prior written consent.
                        </p>

                        <p style={{ color: 'black' }}>
                            <h3>
                                Content License
                            </h3>
                            The Services and all software, images, graphics, tools, data, codes, trademarks, copyrights, patents, service marks, logos, audio, videos, music, Curriculum and other content
                            and materials available on the Services (excluding User Content) and the selection and arrangement thereof (collectively, the “Content”) are the exclusive property of TEDDict or
                            its third-party licensors and are protected of copyright laws, trademark laws and all other applicable laws.
                            Our trademarks may not be used in connection with any product or services without the prior written consent of TEDDict.
                            Subject to these terms, we may allow Users to access the content, products or services offered by third parties through hyperlinks (in the form of word link, banners, channels or
                            otherwise), API or otherwise to such third parties’ website. You are cautioned to read such website’s terms and conditions and/or privacy policies before using the sites. You acknowledge
                            that TEDDict has no control over such third-party websites, does not monitor such website and shall not be responsible or liable to anyone for such websites or any content, products or services
                            made available on or through such website.
                            You agree not to undertake any action to undermine the integrity of the computer systems or networks of TEDDict and/or other User nor to gain unauthorized access
                            to such computer systems or networks.
                            You agree not to undertake any action which may undermine the integrity of TEDDict feedback system, such as leaving positive feedback for yourself using secondary
                            User IDs or through third parties or by leaving unsubstantiated feedback for another User.
                        </p>

                        <p style={{ color: 'black' }}>
                            <h3>
                                User Content
                            </h3>
                            TEDDict offers you the opportunity to submit, post, display, transmit, perform, publish, distribute or broadcast content and materials, including, without limitation,
                            articles, photographs, texts, music, video, audio recordings, computer graphics, pictures, data, questions, comments, suggestions (“User Content”) or personally identifiable information.
                            TEDDict may remove any User Content at its discretion.
                            The user may choose to write and post reviews on the Application.
                            We do not encourage children to post any personal information on the Application. If the child shares any personal information, the ill effects of such revelation shall be borne by you solely.
                            However, you can request the Application via e-mail at help@teddict.com to delete any such information posted by you. For the purpose of verification, TEDDict will
                            review and scrutinize such emails before responding.
                            You warrant and represent that your User Content, and the content of any website from which your include a link to any site, or to which you post a link from a site, will
                            not be inappropriate. Without limitation, content (and the content of third-party websites) may be considered inappropriate if:
                            <li>
                                It is misleading in any way, and/or it gives a false impression as to its origins or approvals;
                            </li>
                            <li>
                                It is defamatory, plagiarized (including plagiarism for your own work), abusive, malicious, threatening, false, misleading, offensive, insulting, discriminatory,
                                profane, harassing, racist, sexist, indecent, obscene, pornographic, hateful or it advocates violence;
                            </li>
                            <li>
                                It is in breach of confidentiality or another person’s privacy or other rights, or of nay duty owned by you;
                            </li>
                            <li>
                                It prejudices any active or pending legal proceedings of which you are aware;
                            </li>
                            <li>
                                It contains accusations of impropriety or personal criticism of our personnel, editors or reviewers;
                            </li>
                            <li>
                                It infringes any intellectual property rights proprietary to TEDDict or any third party;
                            </li>
                            <li>
                                It is technically harmful (including content containing, without limitation, computer viruses, logic bombs,
                                trojan horses, worms, harmful components, corrupted data or other malicious software, harmful data or conduct
                                and/or contains any other element which is intended to harm TEDDict or any third party, or to carry out or facilitate
                                any fraudulent or dishonest transaction);
                            </li>
                            <li>
                                It advertises or promotes any product or services or makes any requests for donations or financial support

                            </li>
                            <li>
                                It is spam or junk content;
                            </li>
                            <li>
                                It impersonates another person or otherwise misrepresents your identity, affiliation or status;
                            </li>
                            <li>
                                It would be considered a criminal or statutory offense in any jurisdiction, or gives rise to civil liability, or is otherwise unlawful; and/or
                            </li>
                            <li>
                                It is in breach of these Terms and/or of any additional terms.
                            </li>
                        </p>
                        <p style={{ color: 'black' }}>
                            TEDDict will not be held responsible or liable for any of the User Content provided by you on the Application. You must not attempt to avoid or undermine any
                            protections that the Application may put in place for the security and operation of any application.
                            You hereby explicitly consent and grant permission to the Application to record, make videos, audio/sound recordings, take screenshots during the course that
                            are conducted by the Application to the extent required to improve the services offered by the Application.
                            All rights, ownership and intellectual property in the User Content created by your child on or by using the Application, during the period of subscription, shall vest in the TEDDict.
                            You hereby explicitly consent and transfer all such rights, ownership, and intellectual property in the User Content to TEDDict for a worldwide, royalty-free and perpetual use.

                            TEDDict shall have the sole and exclusive right to use, copy, modify, adapt, prepare, derivative works from, distribute, perform and display any/all such User Content.
                            You undertake to use the Application and intellectual property for only the purposes envisaged in the instant terms and shall not use the same for any unauthorized or unlawful purpose.

                            Should you use the Application or intellectual property with any third party’s intellectual property in any manner, then you will be solely responsible to obtain all necessary permissions,
                            authorizations and licenses from the said third party.
                            In the event you use the Application and intellectual property beyond the limited rights granted to you under these Terms, then you shall be solely responsible for the same and
                            shall indemnify TEDDict for all losses, claims and damages in this regard.
                            You will be solely responsible to ensure that any content or intellectual property created by the child shall not contain any bugs, virus and malware or infringe any third party’s
                            intellectual property rights or violate any applicable laws (including data protection policy and the personal data protection act) in any manner.
                        </p>
                        <p style={{ color: 'black' }}>
                            By uploading, submitting, creating or publishing your User Content to or through the Services, you represent and warrant that:
                            <li>You are the creator and owner of your User Content and that you have the authority to use or have the necessary licenses, rights, consents and permissions to publish the User Content you submit.</li>
                            <li>Your User Content does not and will not:</li>
                            <li>Infringe, violate or misappropriate any third-party right, including any copyright, trademark, patent, trade secret, moral right, privacy right, right of publicity, or any other intellectual property or proprietary right.</li>
                            <li>Slander, defame, libel or invade the right of privacy, publicity or other property rights of any other person.</li>
                            <li>Your User Content does not contain any viruses, adware, spyware, worms or other malicious code.</li>
                        </p>

                        <p style={{ color: 'black' }}>
                            <h3>
                                User’s Representations and Warranties
                            </h3>
                            You expressly acknowledge and undertake that:
                            <li>
                                You are competent and have all the necessary legal rights to enter into this agreement on behalf of the child.
                            </li>
                            <li>
                                You grant your consent to the Application for your child to attend and participate in the classNamees, courses, tests, sessions, and/or any other program conducted and/or organized by the TEDDict on its application and in relation to the Services provided by the Application.

                            </li>
                            <li>
                                You undertake that the participation of the child and all the activities done by the child will be under your direct and constant supervision.
                            </li>
                            <li>
                                Your further accept full and complete liability arising out of the child’s acts, whether direct or indirect.
                            </li>
                        </p>
                        <p style={{ color: 'black' }}>
                            <h3>
                                Payment and Refund
                            </h3>
                            The Application is a paid service and the payments made by you shall be according to the plans opted by you through this Application.
                            <li>You expressly agree to pay the fees for the plans that you purchase, and you authorize TEDDict to charge you as per the applicable payment mode opted by you.</li>
                            <li>All payments shall be through the payment mechanism put in place by TEDDict and you shall be responsible for paying all the fees and applicable taxes in a timely
                                manner as per the mechanism associated with the plan availed by you.</li>
                            <li>Users have been given various options to choose and proceed with the payment for the Services via payment mechanisms including but not limited to, Card Payment facilities,
                                EMI Payment, Online Bank Transfer and Wallet Payment.</li>
                            <li>With reference to third party services, the payment gateway mechanisms are governed by the terms and conditions of the third-party
                                providers as listed on the Application and the user agrees to be bound by those terms.</li>
                            <li>
                                Valid Credit/ Debit / Cash Card/ online bank transfer and other payment instruments are processed using a Credit Card payment gateway or
                                appropriate payment system infrastructure and the same will also be governed by the terms agreed to between the Users and the respective issuing
                                bank and payment instrument issuing company.
                            </li>
                            <li>We shall not be liable for any unauthorized use, fraud, payment refunds, lost amount, etc. in the transaction. </li>
                            <li>The amount of refund payable shall only be limited to the amount paid by the User for Services which were not rendered to the User by us.</li>
                            <li> In case of refund of EMI transactions, User will be charged interest as per bank’s regulations. </li>
                            <li> We do not provide any refund. All the payment made are NONREFUNDABLE. </li>
                            <li> Any charges related to application publishing (e.g. Charges levied by app store, play store) will be incurred by the User.</li>
                            <li> We reserve the right to change any fees at any time at our sole discretion and any change, update, or modification in the fee shall
                                become effective immediately upon the same being posted/uploaded or notified on our Application.

                            </li>

                        </p>
                        <p style={{ color: 'black' }}>
                            <h3>
                                Intended Purpose of Use
                            </h3>
                            Intended Purpose of Use
                            <li>Any application, code or content created using the Application are intended to be used solely as prototypes and for evaluation of validity and practicability of ideas.</li>
                            <li>All such applications, codes, and/or content may be solely created for educational purposes and gathering feedback by the tutors and students and are in no way fit for or meant to be used for any commercial use.</li>
                            <li>Any other use of such application, codes or content, other than the use specified herein is prohibited by TEDDict and the TEDDict shall not be liable to the same and you shall indemnify TEDDict for any loss, claims or damages suffered by TEDDict in this regard.
                            </li>
                            <p style={{ color: 'black' }}>
                                <h3>User Communication</h3>

                                You hereby explicitly consent to receive email, telephone, text messages from TEDDict, for the purpose of providing alerts and information related to Services.
                                Reply ‘STOP’ on the same number to stop receiving any further SMS.
                                Reply ‘HELP’ to get help.
                                Standard data charges are applicable for SMS.
                            </p>

                            <h3>Rule of Conduct</h3>

                            Users must comply with the laws that apply to you in the location that you access Application’s Services from.
                            If any laws applicable to you restrict or prohibit you from using Services of Application, you must comply with those
                            legal restrictions or, if applicable, stop accessing and/or using the Services of the Application. You promise that all the
                            information you provide to Application on accessing and/or using the Services of Application is and shall remain true, accurate
                            and complete at all times.
                            Notwithstanding any other provisions of these Terms you agree and undertake not to:
                            <li>Hack, attempt to hack, modify, adapt, merge, translate, decompile, disassemble, reverse engineer or create derivative
                                works out of the Services or any part of them (save to the extent which expressly cannot be prohibited in accordance with the applicable law in your jurisdiction) </li>
                            <li>Remove, disable, modify, add to or tamper with any program code or data, copyright, trademark, or other proprietary notices
                                and legends contained on or in the Services; </li>
                            <li>Create software which mimics any data or functionality in the Service;</li>
                            <li>Use or deal in the Service, except as permitted by these Terms;</li>
                            <li>Include contact details intended to enable communication outside of the Service, in any communication;</li>
                            <li>Use your access to the Service or information gathered from it, for the sending of unsolicited bulk email;</li>
                            <li>Make any public, business or commercial use of the Service or any part of them;</li>
                            <li>Provide hypertext links, URL links, graphic links, hyperlinks or other direct connection for profit or gain to the Service without prior written permission of the Application;</li>
                            <li>Make the Service or any part of it available to any third party (please note this does not stop you from fairly and honestly providing links to the Application or showing either to other people);</li>
                            <li>Use or process the Service or any part of them unfairly or for any illegal or immoral purpose;</li>
                            <li>Delete or obscure any copyright or other proprietary notice on the Service.</li>
                            The Service may only be accessed and used via authorised servers. You must take no action to bypass authorized servers and/or use third party software to modify any aspect of the Service, whether for the purpose of securing and unfair advantage over other users, and you expressly consent to TEDDict’s monitoring your computer’s random access memory for the purpose of identifying said unauthorized third-party programs.

                            <p style={{ color: 'black' }}>
                                <h3>Termination</h3>

                                Without limiting any other rights that TEDDict may have, TEDDict may remove, restrict, cancel, or suspend access to and/or use of the Application, Services provided by us and any part of it, if we consider (in the sole discretion of TEDDict) that:
                                <li> you have breached any of these Terms;</li>
                                <li>you do not fall under the age group of 11 – 18 years;</li>
                                <li> you have made inappropriate submission of your work;</li>
                                <li>you have created multiple accounts or multiple trial accounts.</li>

                                You may also terminate your agreement with the Application by ceasing to access the Application, Service, deleting all copies of the Services or part thereof within your control.
                                Termination shall not affect any rights or remedies, which have accrued up to the time of termination.
                            </p>

                            <p style={{ color: 'black' }}>
                                <h2>DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY</h2>
                                To the maximum extent permitted by law, the Services provided by TEDDict on or through the Application are provided “AS IS”, “AS AVAILABLE”
                                and “WITH ALL FAULTS” and TEDDict hereby expressly disclaims any and all warranties, express or implied, including but not limited to, any
                                warranty of condition, quality, durability, performance, accuracy, reliability, merchantability or fitness for a particular purpose. All such
                                warranties, representations, conditions and undertakings are hereby excluded.
                                To the maximum extent permitted by law, TEDDict makes no representations or warranties about the validity, accuracy, correctness, reliability,
                                quality, stability, completeness or currentness of any information provided through the Application. TEDDict does not represent or warrants that
                                the defect will be corrected, that the services or the server that makes it available are free of virus or other harmful components or that the Services
                                will otherwise meet your needs or expectations.
                                Any material downloaded or otherwise obtained through the Application or Services is done at each User’s sole discretion and risk and each User is solely
                                responsible for any damages to its own or to TEDDict’s computer systems or any loss of data that may result from the download of any such material. No advice
                                or information, whether oral or written, obtained by any User from TEDDict or through or from the Sites shall create any warranty not expressly stated herein.
                                The foregoing does not affect any warranties which cannot be excluded or limited under applicable law.

                            </p>

                            <p style={{ color: 'black' }}>
                                <h3>
                                    Limitation of Liability
                                </h3>

                                TEDDict shall not be liable for any special, direct, indirect, punitive, incidental, or consequential damages or any damages whatsoever, (including but not limited to,
                                damages for loss of profits or savings, business interruption, loss of information), whether in contract, negligence, tort, equity or otherwise or any other damages resulting from any of the following:
                            </p>
                            <ul style={{ color: 'black' }} type="i">
                                <li>The use or the inability to use the Application or Services;
                                </li>
                                <li>Any defect in goods, samples, data, information or services purchased or obtained from a User or any other third party through the Application or Services;
                                </li>
                                <li> Unauthorised access by third parties to data or private information of any User;</li>
                                <li> Any matters relating to the Application or Services, however arising, including negligence.
                                </li>
                                <li> Notwithstanding any of the foregoing provisions, the aggregate liability of TEDDict, our employees, agents, affiliates, representatives or anyone acting on our behalf with respect to each User for all claims
                                    arising from the access to or use of the Application or Services during any calendar year shall be limited to the greater of -
                                    The amount of fees the User has paid to TEDDict in exchange for the access to or use of the Application or Services during the calendar year and
                                    The maximum amount permitted under the applicable law.
                                </li>
                                <li> The preceding sentence shall not preclude the requirement by the User to prove actual damages. All claims arising from the use of the Application or Services
                                    must be filed within one (1) year from the date of cause of action arose or such longer period as prescribed under the applicable law governing the Terms.
                                    The limitations and exclusions of liability to you under the Terms shall apply to the maximum extent permitted by law and shall apply whether or not TEDDict has been advised
                                    of or should have been aware of the possibility of any such losses arising.</li>

                            </ul>
                            <h3 style={{ color: 'black' }} > Indemnification </h3>
                            <p>
                                Each User hereby agrees to indemnify and hold TEDDict, our affiliates, directors, officers and employees harmless, from any and all losses, claims, liabilities
                                (including legal cost on a full indemnity basis) which may arise from such User’s access to or use of the Application or Services (including but not limited to the
                                submission, posting, or display of such User’s information and User Content on the Site, under promotion services) or from your breach of any of the representations
                                and warranties made by User to TEDDict, including but not limited to those above.
                                Each User hereby agrees further to indemnify and save TEDDict, our affiliates, directors, officers and employees harmless, from any and all losses, damages, claims, liabilities (including
                                legal costs on a full indemnity basis) which may arise, directly or indirectly, as a result of any claims asserted by Third Party Rights claimant or other third parties relating to the User
                                Content, information and/or products offered or displayed on the Application.
                                Each User further agrees that TEDDict is not responsible and shall have no liability to you, for any material rests entirely with each User. TEDDict reserves the right, at its own expense,
                                to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you shall cooperate with TEDDict in asserting any available defenses.
                            </p>
                        </p>
                        <h3>FORCE MAJEURE</h3>
                        <p>
                            TEDDict shall not be liable for or required to pay compensation of any nature whatsoever for any loss arising from the unavailability, inconvenience or failure of the services or systems due to any of these reasons:
                            system shut-down for maintenance; inability to transmit data due to failures in communications terminals or telecommunications equipment; systems failure and inability to perform its functions due to “Force Majeure
                            Events” (including but not limited to, typhoons, earthquakes, tsunamis, floods, power failure, fires, storms, war, political unrest, labour strikes, shortage of labour or materials, riots, insurrections, civil disturbances,
                            terrorists attack, explosions, acts of God, governmental actions, orders of domestic or foreign courts or tribunals, non-performance of third parties); or suspension or delay of services or systems failure due to reasons beyond
                            the reasonable control of TEDDict such as hacker or cyber-attacks, technical adjustments or failure of the telecommunications department, application upgrades, third party problems or any suspension or disruption of transportation or business operation (including but not limited to, delays or disruption of the resumption of work or operation ordered by any government agency) in the event of a national or regional spread of epidemic or pandemic.
                        </p>
                        <p>Unless otherwise stated, all fees due for the Paid Subscriptions
                            are payable in advance, and will be billed automatically to the Payment Method at the start of the monthly or annual Paid Subscription period, as
                            applicable. Unless otherwise stated, Paid Subscriptions will auto-renew until you elect to cancel your access to Paid Subscriptions. All purchases
                            of Paid Subscriptions are final and non-refundable, except at our sole discretion and in accordance with the rules governing each Paid Subscription.
                        </p>
                        <p>
                            <h3>
                                INTELLECTUAL PROPERTY RIGHTS
                            </h3>
                            All title, ownership and intellectual property rights in the Application, Curriculum and Content shall remain with TEDDict, our affiliates or licensors, as the case may be. All rights not otherwise claimed under the Terms or by TEDDict are hereby reserved.
                            TEDDict is the sole owner or lawful licensee of all the rights and interests in the Application, Curriculum and the Content. The Application, Curriculum and the Content embody trade secrets and other intellectual property rights protected under worldwide copyright and other laws.
                            “TEDDict” and the related icons and logos are registered trademarks or trademarks or service marks of TEDDict, in the relevant jurisdictions and are protected under applicable copyright, trademark and other proprietary right laws, the unauthorized copying, modification, use or publication of these marks is strictly prohibited.
                            TEDDict may have independent third parties involved in the provision of the Application or Services (eg. The authentication and verification service providers). You may not use any trademarks, service marks or logos of such independent third parties without approval from such parties.
                            To the large extent permissible under applicable law, all rights, title and interest to all derivative work created by TEDDict and/or its affiliates using User Content pursuant to the Terms shall belong to TEDDict, which may be freely assignable, licensable or grantable by TEDDict to any third party or its affiliates.
                            Notices
                            All legal notice or demands to or upon TEDDict shall be in writing and sent to TEDDict by certified mail to the following entity:
                            <a href="mailto:help@teddict.com" _blank>help@teddict.com</a>
                        </p>
                        <p>
                            All the notices shall be effective from the date when they are received by TEDDict ple.com in an above-mentioned manner.
                            All legal notices or demands to or upon a User shall be effective if either delivered personally, sent by courier, certified mail, by facsimile or email to the last known correspondence, fax or email address provided by the User to the TEDDict or by posting such notice or demand on an area of the Sites that is publicly accessible without a charge.
                            Notice to a User shall be deemed to be received by such User if and when:
                            <li>Immediately upon TEDDict posting such notice on an area of the Application that is publicly accessible without charge or</li>
                            <li>
                                TEDDict is able to demonstrate that communication, whether in physical or electronic form, has been sent to such User.
                            </li>
                            You agree that all agreements, notices, demands, disclosures and other communications that TEDDict sends to you electronically will satisfy any legal requirement that such communication should be in writing.
                        </p>

                        <h3> MISCELLANEOUS</h3>

                        <li><strong>Entire Agreement:</strong>
                            Terms along with Privacy Policies, constitute the entire agreement between you and TEDDict with respect to and govern your use of the
                            Applciation and Services, superseding any prior written or oral agreements in relation to the same subject matter herein.</li>

                        <li><strong>Severability:</strong> If any provision of the Terms is held to be invalid or unenforceable, such provision shall be deleted and the remaining provision shall remain valid and enforceable.</li>

                        <li><strong>Survival;</strong> the terms and conditions of these Terms are intended to survive the termination or expiration of Services are including but not limited to, Indemnification, Disclaimer of Warranty,
                            Limitation of Liability, Governing laws and Dispute Resolution</li>

                        <li><strong>Note:</strong> Headings are for reference purposes only and in no way define, limit, construe or describe the scope or extent of such clause.</li>

                        <li><strong>Assignment:</strong> TEDDict’s failure to enforce any rights to assign the Terms (including all of our rights, titles, benefits, interests and
                            obligations and duties in the Terms to any person or entity (including any affiliates of TEDDict). You may not assign, in whole or part, the Terms to any person or entity.</li>

                        <li><strong>Waiver:</strong> No waiver of any terms of this Agreement shall be treated as a further or continuing waiver of such term or any other term and the Application’s failure to
                            assert any right or provision under this Agreement shall constitute a waiver of such right or provision.</li>

                        <li><strong>Waiver:</strong> No waiver of any terms of this Agreement shall be treated as a further or continuing waiver of such term or any other term and the Application’s
                            failure to assert any right or provision under this Agreement shall constitute a waiver of such right or provision.</li>

                        <li> <strong>Governing laws and Dispute Resolution:</strong> This Agreement shall be governed and construed with the laws of Pakistan. The parties to agreement hereby submit
                            to arbitrate any dispute arising from these Terms or the related Application, Services or Content to the exclusive jurisdiction of the courts of Pakistan,
                            except otherwise stipulated under applicable law.</li>

                        The Parties agrees:

                        <li>To notify each other in writing about the dispute that arises within thirty (30) days,</li>

                        <li>To attempt to resolve the dispute amicably through mediation before arbitration,</li>

                        <li>That arbitration will occur at any place decided by TEDDict, within Pakistan.</li>

                        <li>That the arbitration proceedings will be conducted in accordance with the applicable law.</li>

                        <li>The proceedings will be conducted in the English language.</li>

                        <li>The arbitration award will be binding on all the parties.</li>


                        For any queries you may contact us at help@teddict.com we would be more than happy to help you.


                        <h3>Questions? Concerns? Suggestions?</h3>
                        <p >Please <a style={{ textDecoration: 'underline'}} href="mailto:help@teddict.com " _blank><strong>contact us</strong></a> to report any
                            violations of these Terms of Service or to pose any questions regarding these Terms of Service or the Service.
                        </p>
                    </div>
                </main>

            </div>
        </>
    )
}

export default TermsConditions