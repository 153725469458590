import React from 'react';

function OurValues() {
    return (
        <section className="relative">
            <div className="absolute left-0 right-0 m-auto w-px p-px h-20 bg-gray-400 transform -translate-y-1/2"></div>

            <div className="flex flex-col justify-center m-auto">
                <div className="pt-12 md:pt-20">
                    {/* Section header */}
                    <div className="max-w-3xl mx-auto text-center">
                        <h2 className="h2 mb-4">Our Values</h2>
                    </div>
                    <div class="flex flex-col justify-center m-auto">
                        <div class="flex md:flex-row flex-col bg-teal-200 justify-center md:text-left text-center border-b-4 border-dashed border-gray-300">
                            <div class="flex flex-col justify-center items-center relative">
                                <div class="w-56 h-12 md:flex hidden justify-center">
                                    <div class="h-full border-teddict-blue border-dashed"></div>
                                </div>
                                <img alt="step1" class="w-56 h-56 rounded-full shadow lg:my-5 my-10 object-scale-down" src={require('../images/value1.png').default} />
                                <div class="rounded-full lg:w-12 lg:h-12 w-16 h-16 text-xl text-teal-100 bg-teddict-blue font-black flex justify-center items-center lg:absolute top-0 right-0 lg:mt-16 shadow-lg lg:mr-2">1</div>
                                <div class="w-56 h-12 md:flex hidden justify-center">
                                    <div class="h-full border-r-4 border-teddict-blue border-dashed"></div>
                                </div>
                            </div>
                            <div class="ml-5 p-10 flex flex-col justify-center max-w-2xl rounded bg-teal-200">
                                {/* <div class="text-xs uppercase font-bold text-teal-500">Step 1 - Idea</div> */}
                                <div class="md:text-3xl text-xl font-bold text-teal-700">NPCs to Protagonists</div>
                                <div class="mt-4 text-teal-800">We create proactive players in life and convert NPCs into Protagonists. We aim to enable them in such a way that they become the main characters of their story and take charge of all the aspects in their lives.</div>
                            </div>
                        </div>
                        <div class="flex md:flex-row flex-col bg-orange-200 justify-center md:text-left text-center border-b-4 border-dashed border-gray-300">
                            <div class="flex flex-col justify-center items-center relative">
                                <div class="w-56 h-12 md:flex hidden justify-center">
                                    <div class="h-full border-r-4 border-teddict-red border-dashed"></div>
                                </div>
                                <img alt="step2" class="w-56 h-56 rounded-full shadow lg:my-5 my-10 object-scale-down" src={require('../images/value2.png').default} />
                                <div class="rounded-full lg:w-12 lg:h-12 w-16 h-16 text-xl text-orange-100 bg-teddict-red font-black flex justify-center items-center lg:absolute top-0 right-0 lg:mt-16 shadow-lg lg:mr-2">2</div>
                                <div class="w-56 h-12 md:flex hidden justify-center">
                                    <div class="h-full border-r-4 border-teddict-red border-dashed"></div>
                                </div>
                            </div>
                            <div class="ml-5 p-10 flex flex-col justify-center max-w-2xl rounded bg-orange-200">
                                {/* <div class="text-xs uppercase font-bold text-orange-500">Step 2 - Collaboration</div> */}
                                <div class="md:text-3xl text-xl font-bold text-orange-700">Progress over Perfection</div>
                                <div class="mt-4 text-orange-800">We measure progress, not results. We value improvements more than outcomes because we know that effort is not directly proportional to results. However, we also believe that consistent efforts do bring results!</div>
                            </div>
                        </div>
                        <div class="flex md:flex-row flex-col bg-indigo-200 justify-center md:text-left text-center border-b-4 border-dashed border-gray-300">
                            <div class="flex flex-col justify-center items-center relative">
                                <div class="w-56 h-12 md:flex hidden justify-center">
                                    <div class="h-full border-r-4 border-teddict-green border-dashed"></div>
                                </div>
                                <img alt="step3" class="w-56 h-56 rounded-full shadow lg:my-5 my-10 object-scale-down" src={require('../images/value3.png').default} />
                                <div class="rounded-full lg:w-12 lg:h-12 w-16 h-16 text-xl text-indigo-100 bg-teddict-green font-black flex justify-center items-center lg:absolute top-0 right-0 lg:mt-16 shadow-lg lg:mr-2">3</div>
                                <div class="w-56 h-12 md:flex hidden justify-center">
                                    <div class="h-full border-r-4 border-teddict-green border-dashed"></div>
                                </div>
                            </div>
                            <div class="ml-5 p-10 flex flex-col justify-center max-w-2xl rounded bg-indigo-200">
                                {/* <div class="text-xs uppercase font-bold text-gray-900">Step 3 - Planification</div> */}
                                <div class="md:text-3xl text-xl font-bold text-indigo-700">Mediocrity is Ew!</div>
                                <div class="mt-4 text-indigo-800">We, as an organization, cleanse mediocrity from processes, people, and projects. We push ourselves and others around us to be better every day regardless of how minor the improvements are.</div>
                            </div>
                        </div>
                        <div class="flex md:flex-row flex-col justify-center md:text-left text-center border-b-4 border-dashed border-gray-300">
                            <div class="flex flex-col justify-center items-center relative">
                                <div class="w-56 h-12 md:flex hidden justify-center">
                                    <div class="h-full border-r-4 border-yellow-300 border-dashed"></div>
                                </div>
                                <img alt="step4" class="w-56 h-56 rounded-full shadow lg:my-5 my-10 object-scale-down" src={require('../images/value4.png').default} />
                                <div class="rounded-full lg:w-12 lg:h-12 w-16 h-16 text-xl text-gray-900 bg-yellow-300 font-black flex justify-center items-center lg:absolute top-0 right-0 lg:mt-16 shadow-lg lg:mr-2">4</div>
                                <div class="w-56 h-12 md:flex hidden justify-center">
                                    <div class="h-full  border-yellow-400 border-dashed"></div>
                                </div>
                            </div>
                            <div class="ml-5 p-10 flex flex-col justify-center max-w-2xl rounded ">
                                {/* <div class="text-xs uppercase font-bold text-gray-900">Step 4 - Implementation</div> */}
                                <div class="md:text-3xl text-xl font-bold text-gray-900">Efficiency is key</div>
                                <div class="mt-4 text-gray-900">Every day we improve our systems to be better at projects we’re executing. Finding ways to make our processes more efficient and impactful- to increase productivity -is always something we strive for at this organization.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    );
}

export default OurValues;
