import React from "react";
import Header from "../partials/Header";
import HeroAbout from "../partials/HeroAbout";
import OurValues from "../partials/OurValues";
import FoundingTeam from "../partials/FoundingTeam";
import Members from "../partials/Members";
import Footer from "../partials/Footer";

function Home() {
	return (
		<div className="flex flex-col min-h-screen overflow-hidden">
			{/*  Site header */}
			<Header />
			{/*  Page content */}
			<main className="flex-grow">
				{/*  Page sections */}
				<HeroAbout />
				<OurValues />
				<FoundingTeam />
				<Members />
			</main>

			{/*  Site footer */}
			<Footer />
		</div>
	);
}

export default Home;
