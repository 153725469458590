import React from 'react'

function TeamMemberCard({ name = 'Team Member',
    image, title = 'Title',
    blackAndWhiteFilter = true,
    twitterHandle = '#',
    linkedinHandle = '#' }) {
    return (
        <div class="flex mt-5 py-4 px-4 w-72 bg-gray-100 rounded-xl hover:shadow-xl transform hover:scale-110 transition duration-500 mx-auto md:mx-0 border-2 border-gray-300">
            <div class={`w-sm flex flex-col items-center justify-center p-4 ${blackAndWhiteFilter ? `display-image` : ``}`} style={{ margin: '0 auto' }}>
                <img class="inline-flex shadow-lg border border-gray-200 rounded-full overflow-hidden h-40 w-40" src={image} alt="" />
                <div class="mt-4 text-gray-900 text-center">
                    <h1 class="text-xl font-bold">{name}</h1>
                    <p class="mt-4 text-gray-600">{title}</p>
                    {/* Social links */}
                    <div className="flex justify-center items-center">
                        <div>
                            <a href={twitterHandle} className="flex justify-center items-center text-gray-600 hover:text-gray-900 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out" aria-label="Twitter">
                                <svg className="w-8 h-8 fill-current" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6.066 9.645c.183 4.04-2.83 8.544-8.164 8.544-1.622 0-3.131-.476-4.402-1.291 1.524.18 3.045-.244 4.252-1.189-1.256-.023-2.317-.854-2.684-1.995.451.086.895.061 1.298-.049-1.381-.278-2.335-1.522-2.304-2.853.388.215.83.344 1.301.359-1.279-.855-1.641-2.544-.889-3.835 1.416 1.738 3.533 2.881 5.92 3.001-.419-1.796.944-3.527 2.799-3.527.825 0 1.572.349 2.096.907.654-.128 1.27-.368 1.824-.697-.215.671-.67 1.233-1.263 1.589.581-.07 1.135-.224 1.649-.453-.384.578-.87 1.084-1.433 1.489z" />
                                </svg>
                            </a>
                        </div>
                        <div className="ml-4">
                            <a href={linkedinHandle} className="flex justify-center items-center text-gray-600 hover:text-gray-900 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out" aria-label="Linkedin">
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8 fill-current" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2 16h-2v-6h2v6zm-1-6.891c-.607 0-1.1-.496-1.1-1.109 0-.612.492-1.109 1.1-1.109s1.1.497 1.1 1.109c0 .613-.493 1.109-1.1 1.109zm8 6.891h-1.998v-2.861c0-1.881-2.002-1.722-2.002 0v2.861h-2v-6h2v1.093c.872-1.616 4-1.736 4 1.548v3.359z" /></svg>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default TeamMemberCard
