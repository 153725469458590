import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';

import background from "../images/Whangaehu.png"
import logo from '../images/logo.png';

function HeroAbout() {
    return (
        <section className="relative">
            {/* Illustration behind hero content */}
            <div className=" hero-image bg-right-bottom bg-cover flex absolute bottom-0 left-0 right-0 top-0 w-full bg-white filter blur-sm" style={{ backgroundImage: `url(${background})`, opacity: 0.4 }}>
            </div>
            {/* <div className="max-w-9xl mx-auto px-4 sm:px-6 md:bg-opacity-10 bg-gray-100 blur shadow-lg"> */}
            <div className="max-w-6xl mx-auto px-4 sm:px-6">
                {/* Hero content */}
                <div className="pt-32 pb-12 md:pt-40 md:pb-20 ">
                    {/* Section header */}
                    <div className="text-center pb-12 md:pb-16">
                        <div className="mx-auto relative">
                            <div className="bg-white rounded border-2">
                                <div className="md:grid md:grid-cols-12 md:gap-6 py-5 px-10 ">
                                    <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-4 lg:col-span-4 px-5 my-auto order-last">
                                        <div className="relative flex flex-col text-left lg:text-left">
                                            <div className="relative inline-flex flex-col">
                                                {/* Logo */}
                                                <Link to="/" className="inline-block" aria-label="Cruip">
                                                    <img className="h-50 w-auto sm:h-50 p-5" src={logo} alt="" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div >
                                    <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-8 lg:col-span-8 px-3 py-3 my-auto text-center md:text-left">
                                        <div class="md:text-3xl text-lg font-bold text-teal-700">About & Special Mention</div>
                                        <div class="mt-4 text-teal-800 mb-5">TEDDict is a platform focused on designing learning environments. Our aim is to educate the young population outside the conventional schooling system of today’s age. It was founded by 3 siblings at the ages of 18, 16 and 14! These kids, being homeschooled,  were allowed to explore their strengths and learn by doing. Now they empower and help the youth to tap into their own potential!
                                        </div>
                                        <div class="mt-4 text-blue-900 mb-10">Team Pakistan bags gold at regional tech awards. Meet the education startup teddict and teenage trio behind it
                                        </div>
                                        <div className="-mx-3 ml-auto md:mt-auto">
                                            <div className="">
                                                <button className="btn text-white bg-gray-900 hover:bg-teddict-blue w-40" onClick={(e) => {
                                                    e.preventDefault();
                                                    window.location.href = 'https://www.techjuice.pk/meet-the-education-startup-teddict-and-teenage-trio-behind-it/';
                                                }}>Learn More</button>
                                            </div>
                                        </div>
                                    </div>
                                </div >
                            </div>
                        </div>
                    </div>

                    {/* Hero image */}
                    <div>
                        <div
                            className="relative flex justify-center mb-8"
                            data-aos="zoom-y-out"
                            data-aos-delay="450"
                        >
                        </div>
                    </div>
                </div>
            </div>
            {/* </div> */}
        </section>
    );
}

export default HeroAbout;
