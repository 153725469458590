import React from "react";
import Header from "../partials/Header";
import HeroContact from "../partials/HeroContact";
import ContactUs from "../partials/ContactUs";
import Footer from "../partials/Footer";

function Home() {
	return (
		<div className="flex flex-col min-h-screen overflow-hidden">
			{/*  Site header */}
			<Header />
			{/*  Page content */}
			<main className="flex-grow">
				{/*  Page sections */}
				<HeroContact />
				<ContactUs />
			</main>

			{/*  Site footer */}
			<Footer />
		</div>
	);
}

export default Home;
