import React, { useState, useEffect } from "react";

function HeroContact() {
    return (
        <section className="relative">
            {/* Illustration behind hero content */}
            <div className=" hero-image bg-blue-200 bg-right-bottom bg-cover flex absolute bottom-0 left-0 right-0 top-0 w-full">
            </div>
            <div className="max-w-6xl mx-auto px-4 sm:px-6">
                {/* Hero content */}
                <div className="pb-12 md:pb-20">
                    {/* Section header */}
                </div>
            </div>
        </section>
    );
}

export default HeroContact;
