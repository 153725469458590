import React from 'react';

function Testimonials() {
  return (
    <section className="relative">

      {/* Illustration behind content */}
      <div className="absolute left-0 right-0 m-auto w-px p-px h-20 bg-gray-400 transform -translate-y-1/2"></div>
      <div className="absolute left-1/2 transform -translate-x-1/2 bottom-0 pointer-events-none -mb-32" aria-hidden="true">
      </div>

      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
            <h2 className="h2 mb-4">Testimonial</h2>
          </div>

          {/* Testimonials */}
          <div className="max-w-3xl mx-auto mb-12" data-aos="zoom-y-out">
            <div className="relative flex items-start border-2 border-gray-200 rounded bg-white">

              {/* Testimonial */}
              <div className="text-center px-12 py-8 pt-20 mx-4 md:mx-0">
                <div className="absolute top-0 -mt-0 left-1/2 transform -translate-x-1/2">
                  <svg className="absolute top-0 right-0 -mt-0 -mr-8 w-16 h-16 fill-current text-indigo" viewBox="0 0 64 64" aria-hidden="true" xmlns="http://www.w3.org/2000/svg">
                    <path d="M37.89 58.338c-2.648-5.63-3.572-10.045-2.774-13.249.8-3.203 8.711-13.383 23.737-30.538l2.135.532c-6.552 10.033-10.532 17.87-11.939 23.515-.583 2.34.22 6.158 2.41 11.457l-13.57 8.283zm-26.963-6.56c-2.648-5.63-3.572-10.046-2.773-13.25.799-3.203 8.71-13.382 23.736-30.538l2.136.533c-6.552 10.032-10.532 17.87-11.94 23.515-.583 2.339.22 6.158 2.41 11.456l-13.57 8.283z" />
                  </svg>
                </div>
                <blockquote className="text-xl font-medium mb-4">
                  “ The Bootcamp was really exciting and suspenseful and I was always looking forward to the activities. I learned how to gamify boring tasks and learned how to shape my passions and goals. “
                </blockquote>
                <cite className="block font-bold text-lg not-italic mb-1">Ibrahim Ameen</cite>
                <div className="text-gray-600">
                  <span>Student </span> <a className="text-indigo hover:underline" href="">@Beaconhouse School</a>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>
    </section>
  );
}

export default Testimonials;