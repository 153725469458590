import React from 'react';
import { Link } from 'react-router-dom';
import TeamMemberCard from './TeamMemberCard';

function FoundingTeam() {
    return (
        <section className="relative bg-blue-200">

            <div className="max-w-6xl mx-auto px-4 sm:px-6 relative">
                <div className="py-12 md:py-20">

                    {/* Section header */}
                    <div className="max-w-3xl mx-auto text-center pb-10 md:pb-10">
                        <h2 className="h2 mb-4">Our Team</h2>
                        {/* <p className="text-xl text-gray-600">Meet our team</p> */}
                    </div>

                    {/* Items */}
                    <div class="md:flex md:justify-center md:space-x-8 md:pb-5">
                        {/* Team Member component */}
                        <TeamMemberCard
                            name='Asad Ullah Babur'
                            title="Vision and Culture Manager"
                            image={require('../images/people/asadullahbabur.jpg').default}
                            twitterHandle="https://www.twitter.com"
                            linkedinHandle="https://www.linkedin.com" />
                        {/* Team Member component */}
                        <TeamMemberCard
                            name='Ayesha Babur'
                            title="External Project Manager"
                            image={require('../images/people/ayeshababur.jpg').default} />
                        {/* Team Member component */}
                        <TeamMemberCard
                            name='Abdullah Babur'
                            title="Project Manager"
                            image={require('../images/people/abdullahbabur.jpg').default} />
                        {/* Team Member component */}
                    </div>

                    {/* Items */}
                    <div class="md:flex md:justify-center md:space-x-8 md:pb-5">
                        {/* Team Member component */}
                        <TeamMemberCard
                            name='Ali Alam'
                            title="External Projects Manager"
                            image={require('../images/people/alialam.jpg').default} />
                        {/* Team Member component */}
                        <TeamMemberCard
                            name='Farhan Soomro'
                            title="Coaching and Projects"
                            image={require('../images/people/farhansoomro.jpg').default} />
                        {/* Team Member component */}
                        <TeamMemberCard
                            name='Asra Rizwan'
                            title="Communication Strategist"
                            image={require('../images/people/asrarizwan.jpg').default} />
                        {/* Team Member component */}
                        <TeamMemberCard
                            name='Shamoon Irfan'
                            title="Event Manager Karachi"
                            image={require('../images/people/shamoonirfan.jpg').default} />
                    </div>
                    {/* Items */}
                    <div class="md:flex md:justify-center md:space-x-8 md:pb-5">
                        {/* Team Member component */}
                        <TeamMemberCard
                            name='Ahmad Mahmood'
                            title="Instructor & Content Associate"
                            image={require('../images/people/ahmadmahmood.jpg').default} />
                        {/* Team Member component */}
                        <TeamMemberCard
                            name='Emaan Amir'
                            title="Lead Content"
                            image={require('../images/people/emaanamir.jpg').default} />
                        {/* Team Member component */}


                        <TeamMemberCard
                            name='Emad Babur'
                            title="Content Associate"
                            image={require('../images/people/emadbabur.jpg').default} />

                        <TeamMemberCard
                            name='Muhammad Jawwad'
                            title="Outreach and Partnerships"
                            image={require('../images/people/Jawwad.jpg').default} />
                        {/* Team Member component */}
                    </div>
                    {/* Items */}
                    <div class="md:flex md:justify-center md:space-x-8 md:pb-5">
                        {/* Team Member component */}
                        <TeamMemberCard
                            name='Fahad Shaikh'
                            title="Lead Developer"
                            image={require('../images/people/Fahad.png').default} />
                        {/* Team Member component */}
                        <TeamMemberCard
                            name='Arsalan Shakil'
                            title="Director of Video"
                            image={require('../images/people/Arsalan.jpg').default} />
                        {/* Team Member component */}
                        <TeamMemberCard
                            name='Madiha Yasin'
                            title="Instructor"
                            image={require('../images/people/Madiha.jpg').default} />

                        <TeamMemberCard
                            name='Maira Syed'
                            title="Instructor"
                            image={require('../images/people/Maira.jpg').default} />
                        {/* Team Member component */}
                    </div>

                    {/* Items */}
                    <div class="md:flex md:justify-center md:space-x-8 md:pb-5">
                        {/* Team Member component */}

                        {/* Team Member component */}
                        <TeamMemberCard
                            name='Aleena Khawaja'
                            title="Events Manager Lahore"
                            image={require('../images/people/Aleena.jpeg').default} />
                        <TeamMemberCard
                            name='Maahum Syed'
                            title="Instuctor"
                            image={require('../images/people/Maahum.jpg').default} />

                        <TeamMemberCard
                            name='M.Umair'
                            title="Jr.Dev"
                            image={require('../images/people/umair.jpg').default} />
                        {/* <TeamMemberCard
                            name='Aleena Khawaja'
                            title="Events Manager Lahore"
                            image={require('../images/people/Aleena.jpeg').default} /> */}

                        {/* Team Member component */}
                    </div>


                </div>
            </div>
        </section>
    );
}

export default FoundingTeam;
