import React, { useState, useEffect } from "react";

function Product() {
    return (
        <section className="relative">
            <div className="max-w-6xl mx-auto px-4 sm:px-6">
                {/* Hero content */}
                <div className="pt-32 pb-12 md:pt-40 ">
                    {/* Section header */}
                    <div className="text-center">
                        <h1 className="h2 mb-4">Products</h1>
                        <p className="text-xl text-gray-600">TEDDict has been contributing to the field of education since 2015. Discover our previous and on-going projects.</p>
                    </div>
                </div>
                {/* Section content */}
                <div className="pb-12 md:pb-20">
                    {/* Section content */}
                    {/* Item 1 */}
                    <div className="bg-gray-200 rounded mb-10">
                        <div className="md:grid md:grid-cols-12 md:gap-6 p-5">
                            <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-4 lg:col-span-4 px-5 my-auto">
                                <div className="relative flex flex-col text-left lg:text-left">
                                    <div className="relative inline-flex flex-col">
                                        <img alt="step1" class="w-70 my-5 object-scale-down rounded px-3" src="https://images01.nicepage.com/c461c07a441a5d220e8feb1a/a17abde8d83650a582a28432/users-with-speech-bubbles-vector_53876-82250.jpg" />
                                    </div>
                                </div>
                            </div >
                            <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-8 lg:col-span-8 px-5 my-auto text-center md:text-left">
                                {/* <div class="text-xs uppercase font-bold text-teal-500 ">Step 1 - Idea</div> */}
                                <div class="md:text-3xl text-xl font-bold text-teal-700">Protagonist PRO</div>
                                <div class="mt-4 text-teal-800 mb-10">An app designed to unleash the distinctive strengths of each teenager, helping them become the main character of their own lives.</div>
                                <div className="md:float-right ml-auto md:mt-auto">
                                    <div className="px-3">
                                        <button className="btn text-white bg-gray-900 hover:bg-teddict-blue w-40">Learn More</button>
                                    </div>
                                </div>
                            </div>
                        </div >
                    </div>
                    {/* Item 2 */}
                    <div className="bg-gray-200 rounded mb-10">
                        <div className="md:grid md:grid-cols-12 md:gap-6 p-5">
                            <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-4 lg:col-span-4 px-5 my-auto order-last">
                                <div className="relative flex flex-col text-left lg:text-left">
                                    <div className="relative inline-flex flex-col">
                                        <img alt="step1" class="w-70 my-5 object-scale-down rounded px-3" src="https://images01.nicepage.com/c461c07a441a5d220e8feb1a/a17abde8d83650a582a28432/users-with-speech-bubbles-vector_53876-82250.jpg" />
                                    </div>
                                </div>
                            </div >
                            <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-8 lg:col-span-8 px-5 my-auto text-center md:text-left">
                                {/* <div class="text-xs uppercase font-bold text-teal-500 ">Step 1 - Idea</div> */}
                                <div class="md:text-3xl text-xl font-bold text-teal-700">Bootcamps</div>
                                <div class="mt-4 text-teal-800 mb-10"><span>Previous Bootcamps:</span>
                                    Teddict Design Thinking Bootcamp, Teddict Digital Marketing and Branding Bootcamp, and Protagonist PRO Empowerment Bootcamp.
                                </div>
                                <div className="-mx-3 ml-auto md:mt-auto">
                                    <div className="">
                                        <button className="btn text-white bg-gray-900 hover:bg-teddict-blue w-40">Learn More</button>
                                    </div>
                                </div>
                            </div>
                        </div >
                    </div>
                    <div className="bg-gray-200 rounded mb-10">
                        <div className="md:grid md:grid-cols-12 md:gap-6 p-5">
                            <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-4 lg:col-span-4 px-5 my-auto">
                                <div className="relative flex flex-col text-left lg:text-left">
                                    <div className="relative inline-flex flex-col">
                                        <img alt="step1" class="w-70 my-5 object-scale-down rounded px-3" src="https://images01.nicepage.com/c461c07a441a5d220e8feb1a/a17abde8d83650a582a28432/users-with-speech-bubbles-vector_53876-82250.jpg" />
                                    </div>
                                </div>
                            </div >
                            <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-8 lg:col-span-8 px-5 my-auto text-center md:text-left">
                                {/* <div class="text-xs uppercase font-bold text-teal-500 ">Step 1 - Idea</div> */}
                                <div class="md:text-3xl text-xl font-bold text-teal-700">Gamify Work IO</div>
                                <div class="mt-4 text-teal-800 mb-10">A podcast to learn about how to relish the process of achieving your goals and running efficient ecosystems. GamifyWork is a podcast brought to you by teenagers discussing gamification of work environments to improve human efficiency in an era of digitalisation.</div>
                                <div className="md:float-right ml-auto md:mt-auto">
                                    <div className="px-3">
                                        <button className="btn text-white bg-gray-900 hover:bg-teddict-blue w-40">Learn More</button>
                                    </div>
                                </div>
                            </div>
                        </div >
                    </div>
                    {/* Item 2 */}
                    <div className="bg-gray-200 rounded mb-10">
                        <div className="md:grid md:grid-cols-12 md:gap-6 p-5">
                            <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-4 lg:col-span-4 px-5 my-auto order-last">
                                <div className="relative flex flex-col text-left lg:text-left">
                                    <div className="relative inline-flex flex-col">
                                        <img alt="step1" class="w-70 my-5 object-scale-down rounded px-3" src="https://images01.nicepage.com/c461c07a441a5d220e8feb1a/a17abde8d83650a582a28432/users-with-speech-bubbles-vector_53876-82250.jpg" />
                                    </div>
                                </div>
                            </div >
                            <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-8 lg:col-span-8 px-5 my-auto text-center md:text-left">
                                {/* <div class="text-xs uppercase font-bold text-teal-500 ">Step 1 - Idea</div> */}
                                <div class="md:text-3xl text-xl font-bold text-teal-700">Level Up Coaching</div>
                                <div class="mt-4 text-teal-800 mb-10">A year long coaching program catered to each participant’s specific needs: assisting them in refining and accomplishing their goals.
                                </div>
                                <div className="-mx-3 ml-auto md:mt-auto">
                                    <div className="">
                                        <button className="btn text-white bg-gray-900 hover:bg-teddict-blue w-40">Learn More</button>
                                    </div>
                                </div>
                            </div>
                        </div >
                    </div>
                </div>
            </div >
        </section >
    );
}

export default Product;
