import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from '../images/logo.png';

function Header() {

  const [top, setTop] = useState(true);
  const [navbarOpen, setNavbarOpen] = useState(false);

  // detect whether user has scrolled the page down by 10px 
  useEffect(() => {
    const scrollHandler = () => {
      window.pageYOffset > 5 ? setTop(false) : setTop(true)
    };
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, [top]);

  return (
    <header className={`fixed w-full z-30 md:bg-opacity-80 transition duration-300 ease-in-out ${!top && 'bg-white blur shadow-lg'}`}>


      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        {/* <div className="mx-auto px-4 sm:px-6"> */}
        <div className="flex justify-between items-center border-b border-gray-300 py-6 md:justify-start md:space-x-12">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <Link to="/">
              <img className="h-8 w-auto sm:h-12 object-scale-down" src={logo} alt="" />
            </Link>
          </div>
          <div className="-mr-2 -my-2 md:hidden">
            <button type="button" className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500" aria-expanded="false" onClick={() => setNavbarOpen(true)}>
              <span className="sr-only">Open menu</span>
              {/* Heroicon name: outline/menu */}
              <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            </button>
          </div>
          <nav className="hidden md:flex space-x-28">

            <Link to="/" className="text-lg font-medium text-gray-800 hover:text-gray-600">
              Home
            </Link>
            <Link to="/about" className="text-lg font-medium text-gray-800 hover:text-gray-600">
              About
            </Link>
            <Link to="/products" className="text-lg font-medium text-gray-800 hover:text-gray-600">
              Products
            </Link>
            <Link to="/contact" className="text-lg font-medium text-gray-800 hover:text-gray-600">
              Contact
            </Link>
            <Link to="/team" className="text-lg font-medium text-gray-800 hover:text-gray-600">
              Team
            </Link>
          </nav>
        </div>
      </div>

      <div className={"absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top-right" +
        (navbarOpen ? " " : " hidden")
      }
      >
        <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
          <div className="pt-5 pb-6 px-5">
            <div className="flex items-center justify-between">
              <div>
                <img className="h-8 w-auto" src={logo} alt="Workflow" />
              </div>
              <div className="-mr-2">
                <button type="button" className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500" onClick={() => setNavbarOpen(false)}>
                  <span className="sr-only">Close menu</span>
                  {/* Heroicon name: outline/x */}
                  <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
            </div>
            <div className="mt-6">
              <nav className="grid gap-y-8">
                <Link to="/" className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50">
                  {/* Heroicon name: outline/chart-bar */}
                  <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" /></svg>
                  <span className="ml-3 text-base font-medium text-gray-900">
                    Home
                  </span>
                </Link>
                <Link to="/about" className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-200">
                  {/* Heroicon name: outline/cursor-click */}
                  <svg className="flex-shrink-0 h-6 w-6 text-gray-600" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd"></path></svg>
                  <span className="ml-3 text-base font-medium text-gray-900">
                    About
                  </span>
                </Link>
                <Link to="/products" className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-200">
                  {/* Heroicon name: outline/shield-check */}
                  <svg className="flex-shrink-0 h-6 w-6 text-gray-600" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5 5a3 3 0 015-2.236A3 3 0 0114.83 6H16a2 2 0 110 4h-5V9a1 1 0 10-2 0v1H4a2 2 0 110-4h1.17C5.06 5.687 5 5.35 5 5zm4 1V5a1 1 0 10-1 1h1zm3 0a1 1 0 10-1-1v1h1z" clip-rule="evenodd"></path><path d="M9 11H3v5a2 2 0 002 2h4v-7zM11 18h4a2 2 0 002-2v-5h-6v7z"></path></svg>
                  <span className="ml-3 text-base font-medium text-gray-900">
                    Products
                  </span>
                </Link>
                <Link to="/contact" className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-200">
                  {/* Heroicon name: outline/view-grid */}
                  <svg className="flex-shrink-0 h-6 w-6 text-gray-600" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z"></path></svg>
                  <span className="ml-3 text-base font-medium text-gray-900">
                    Contact
                  </span>
                </Link>
                <Link to="/team" className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-200">
                  <svg className="flex-shrink-0 h-6 w-6 text-gray-600" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z"></path></svg>
                  <span className="ml-3 text-base font-medium text-gray-900">
                    Team
                  </span>
                </Link>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
