import React, { useRef, useState, useEffect } from 'react';
import emailjs from 'emailjs-com'

function ContactUs() {
  const form = useRef();
  const [isLoading, setLoading] = useState(false)
  const [isEmailSent, setIsEmailSent] = useState(false)

  useEffect(() => {
    if (isEmailSent) {
      const timer = setTimeout(() => {
        setIsEmailSent(false)
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [isEmailSent]);

  const sendEmail = async (e) => {
    e.preventDefault();
    setLoading(true);
    await emailjs.sendForm('service_wypxvee', 'template_99zlwln', form.current, 'user_il8nYICAuTJS0oM4O0BrJ')
      .then((result) => {
        setLoading(false)
        setIsEmailSent(true)
        form.current.reset();
        console.log(result.text);
      }, (error) => {
        setLoading(false)
        setIsEmailSent(false)
        console.log(error.text);
      });
  };
  return (
    <section className="relative bg-gray-100">
      <div className="relative max-w-7xl mx-auto px-4 sm:px-12">
        <div className="py-16 md:py-20">

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
            <h1 className="h2 mb-4">Get In Touch</h1>
            <p className="text-xl text-gray-600">Reach out to learn more about our products and services.</p>
          </div>
          <div className={`max-w-3xl mx-auto text-center pb-12 md:pb-16 ${isEmailSent ? `` : `hidden`}`}>
            <p className="text-xl text-teddict-green animation-pulse">Message Sent!</p>
          </div>

          {/* Section content */}
          <div className="md:grid md:grid-cols-12 md:gap-6 px-5">
            {/* Content */}
            <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-4 lg:col-span-4 px-5 pb-5" data-aos="fade-right">
              <div className="relative flex flex-col text-left lg:text-left">
                {/* Item 1 */}
                <div className="relative inline-flex flex-col">
                  <div className="flex flex-wrap -mx-3 mb-3">
                    <div className="w-full px-3 py-3">
                      <label className="h1 block text-gray-800 text-lg font-bold mb-1" htmlFor="name">Email Us</label>
                      <p className="font-medium text-lg text-gray-700"><a href="mailto: help@teddict.com">help@teddict.com</a></p>
                    </div>
                  </div>
                  <div className="flex flex-wrap -mx-3 mb-3">
                    <div className="w-full px-3 py-3">
                      <label className="h1 block text-gray-800 text-lg font-bold mb-1" htmlFor="name">Address</label>
                      {/* <p className="font-medium text-lg text-gray-700">Darul-Salam 2nd Floor,</p> */}
                      <p className="font-medium text-lg text-gray-700">Suite 1501, Vital Foakh Tower, Tipu Sultan, Karachi</p>
                      {/* <p className="font-medium text-lg text-gray-700">Karachi, Sindh</p>
                      <p className="font-medium text-lg text-gray-700">Pakistan</p> */}
                    </div>
                  </div>
                  <div className="flex flex-wrap -mx-3 mb-3">
                  </div>
                </div>
              </div>
            </div >
            <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-8 lg:col-span-8 px-5 pb-5" data-aos="fade-left">
              <form ref={form} onSubmit={sendEmail}>
                <div className="flex flex-wrap -mx-3 mb-4">
                  <div className="w-full px-3">
                    <label className="block text-gray-800 text-sm font-medium mb-1" htmlFor="name">Name <span className="text-red-900">*</span></label>
                    <input id="name" type="text" className="form-input w-full text-gray-800 rounded border shadow-md border-gray-200" placeholder="Enter your name" required name="from_name" />
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-4">
                  <div className="w-full px-3">
                    <label className="block text-gray-800 text-sm font-medium mb-1" htmlFor="email">Email <span className="text-red-900">*</span></label>
                    <input id="email" type="email" className="form-input w-full text-gray-800" placeholder="Enter your email address" required name="from_email" />
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-4">
                  <div className="w-full px-3">
                    <label className="block text-gray-800 text-sm font-medium mb-1" htmlFor="message">Message <span className="text-red-900">*</span></label>
                    <textarea id="password" type="password" className="h-40 form-textarea w-full text-gray-800" placeholder="Enter your message" required name="message" />
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mt-6">
                  <div className="w-full px-3">
                    <button className="btn text-white bg-teddict-red hover:bg-gray-800 w-full">Send</button>
                  </div>
                </div>
              </form>
            </div>
          </div >

        </div >
      </div >
    </section >
  );
}

export default ContactUs;