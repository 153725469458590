import React from 'react';
import { Link } from 'react-router-dom';
import TeamMemberCard from './TeamMemberCard';


function FoundingTeam() {
    return (
        <section className="relative bg-green-200">
            <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
                <div className="py-12 md:py-20">
                    {/* Section header */}
                    <div className="max-w-3xl mx-auto text-center pb-10 md:pb-10">
                        <h2 className="h2 mb-4">Founding Team</h2>
                        <p className="text-xl text-gray-600">Meet our founders.</p>
                    </div>
                    {/* Items */}
                    <div class="md:flex md:justify-center md:space-x-8 md:px-14">
                        <TeamMemberCard
                            name='Asad Ullah Babur'
                            title="Interim CEO"
                            image={require('../images/people/asadullahbabur.jpg').default}
                            blackAndWhiteFilter={false} />
                        {/* Team Member component */}
                        <TeamMemberCard
                            name='Ayesha Babur'
                            title="External Projects Manager"
                            image={require('../images/people/ayeshababur.jpg').default}
                            blackAndWhiteFilter={false} />
                        {/* Team Member component */}
                        <TeamMemberCard
                            name='Abdullah Babur'
                            title="Project Manager"
                            image={require('../images/people/abdullahbabur.jpg').default}
                            blackAndWhiteFilter={false} />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default FoundingTeam;
