import React from 'react';
import './style.css';
import Header from '../../partials/Header';

const PrivacyPolicy = () => {
    return (
        <>
            <Header />
            <div class="body">

                <div>

                    <div id="content" class='content'>
                        <div style={{ width: '80%', marginLeft: 40, }}>
                            <h1 id="privacy" class='privacy'>Privacy Policy</h1>
                            <h3 style={{ marginTop: 50 }}>Our policy</h3>
                            <p>
                                Last Updated October 10, 2021
                            </p>
                            <p>
                                TEDDict (here in after referred to as the <strong>“Application”</strong>, <strong> “us”</strong>,<strong> “we”</strong> or <strong>“our”</strong>) has adopted this Privacy Policy to protect the privacy of its application users.
                                This Privacy Policy is only in effect for the mobile applications created by us.
                                This policy describes how we collect, use and keep secure your Personally Identifiable Information
                                in accordance with the Data Protection Policy and the Personal Data Protection Act 2012.
                                By using this application, you agree to this Privacy Policy.

                            </p>
                            <p>
                                <h2>Acceptance of this Privacy Policy</h2>
                                By accessing and using our Services, you signify your acceptance to the terms of this Privacy Policy.
                                Where we require your consent to process your personal information, we will ask for your consent to the collection,
                                use and disclosure of your personal information as described further below.
                                If you do not consent to such usage or you are not comfortable with any of this Privacy Policy, you should immediately
                                discontinue access or use of our Services and application. If you continue accessing our application further,
                                it shall be deemed that you have given us your consent.
                                This Privacy Policy explains what Personal Data (defined below) we collect, how we use and share that data,
                                and
                                your choices concerning our data practices.

                            </p>

                            <h3>1. What Data/Information do we collect?</h3>
                            <p>
                                We collect information that alone or in combination with other information in our possession could be used
                                to
                                identify you (“<strong>Personal Data</strong>”) as follows:
                            </p>
                            <p>
                                <ul>
                                    <li>When you register or subscribe to our Service, as a User, we collect your personal information that can be
                                        identify you (“Personal Identifiable Information” or “PII”) including, but not limited to, your name, personal
                                        identification number, address, mobile number, email address.</li>
                                    <li>We also collect your PII when you participate in forum, survey, contact us, comment, creating profile or rating.</li>
                                    <li>Usage of your information is solely for the purpose of giving you the best services and experience on our Application.</li>
                                    <li>The Application does not collect any detailed personal data or information that are sensitive
                                        (“Sensitive Personal Information”) such as password, financial information, physical, physiological, and mental health
                                        conditions, or biometric information.</li>
                                    <li>You will be required to provide payment (eg. Credit card details) and billing information to make payment
                                        through various payment methods, this information will be processed encrypted and in a secure manner by third-party
                                        payment services. We do not store your payment information on our servers.</li>
                                    <li>We also collect details of transactions carried out through our Application, including without limitations, ID, date
                                        and time of transactions.</li>
                                    <li>When you visit our Application from your mobile device, we get information about your geographical location, however,
                                        you can prevent the location data from being sent to us, by denying access to your location through your browser settings.</li>
                                    <li>When you visit our Application, our server automatically collects information from your browser such as IP address,
                                        proxy server, operating system, web browser and add-ons, device identifier and features, and/or ISP or your mobile
                                        carrier, or the domain from which you are visiting, web-pages you visit, the search terms you use, and any
                                        advertisements on which you click.</li>

                                    <li>We also collect data and information through third-party products on your phone, called log data, when you use our
                                        Service in case of an error.</li>
                                    <li>This log data may include information such as your device internet protocol (IP) address, device name, operating
                                        system version, the configuration of the Application when utilizing our Service, the time and date of your use of the
                                        Service and other statistics.</li>

                                </ul>
                            </p>




                            <h3>2. HOW WE USE THE DATA/INFORMATION COLLECTED BY OUR SITE</h3>
                            <p>
                                We may use Data/Information for the following purposes:
                            </p>
                            <ul>
                                <li>The data collected is used for the purpose for which it has been provided, including without limitation, to enable us to
                                    process and fulfil your subscriptions or other requests</li>
                                <li>To identify you so as to ease out the re-login process at a later time.</li>
                                <li>To notify you about our Services.
                                </li>
                                <li>To gather feedback, to ask you to participate in research to improve your experience of our Services.</li>
                                <li>To send you information about your relationship or transactions with us.</li>
                                <li>To personalise the content that interests you and notify you about the same.</li>
                                <li>
                                    To develop or update features, products and services according to the likes and needs of our customers.
                                </li>
                                <li>
                                    To allow other select companies to send you promotional information about their products and services.</li>
                                <li>To provide customer support and respond to your request, inquiries and compliant.</li>
                                <li>To deter against fraudulent, unauthorized and illegal activity.</li>
                                <li>In order to find points of your interest on our Services, and for the purpose of analytics, we monitor
                                    and analyse web traffic and keep track of your behaviour within our Services.
                                    This enables us to provide services that are relevant to you and boost the quality of our Services.
                                </li>
                            </ul>

                            <h3>3. SHARING AND DISCLOSURE OF DATA/INFORMATION</h3>
                            <p>
                                In certain circumstances we may share Data with third parties without further notice to you
                                as set forth below:
                            </p>
                            <ul>
                                <li>
                                    We share your data with third-party who provide services on our behalf including but not limited to, third party business
                                    partners, affiliates, marketing and advertisement platforms (such as Google, Twitter, Facebook and Instagram), payment service
                                    providers, credit risk assessment providers, customer service (for administrative purposes), cloud computing service providers
                                    (to provide cloud storage service), risk control service (to assess the security of users accounts and transaction risks).
                                </li>
                                <li>
                                    All our third-party service provider is required to take appropriate security measures to protect your data.
                                </li>
                                <li>
                                    We do not allow any third-party to use your data for their own purposes. We permit them to process your data as per our instructions.
                                </li>
                                <li>
                                    By consenting to this Privacy Policy, you consent to share your data with third-party service providers.
                                </li>
                                <li>
                                    We may display third-party content on the Application, including third-party advertising.
                                </li>
                                Third-party content may use cookies, web beacons, or other mechanisms to track your views on the third-party website.
                                <li>
                                    <li>
                                        We implement third-party button including, but not limited to Facebook, Instagram, Twitter, etc share buttons, which
                                        may function as a web beacon.
                                    </li>
                                    <li>
                                        We process your information on our mailing list or for the newsletter and send you messages with some commercial or
                                        promotional information about our Services. However, you have the option to opt-out from our marketing messages by
                                        <a href="mailto:help@teddict.com " _blank>contacting us</a></li> through email.
                                </li>
                                <li>
                                    To our professional advisers, law enforcement agencies, insurers, government and regulatory and other organizations
                                    where we believe it is necessary to comply with applicable laws or to exercise, establish or defend our legal rights
                                    or protect your vital interests or those of any other person, we may also disclose and transfer your personal information
                                    only for the purposes disclosed in this Privacy Policy.
                                </li>
                                <li>
                                    To any other person with your consent to such disclosure.
                                </li>
                                <li>
                                    This Application is not responsible for information collected by any third-party web-beacons and buttons.
                                    You may consult such third-party’s data collection, use and disclosure policies for more information.
                                </li>
                                <li>
                                    This Application is not responsible for the content, privacy and security practices, and policies of
                                    third-party sites or services linked to this Application.
                                </li>

                            </ul>


                            <h3>4. USER DISCRETTION AND OPT-OUT</h3>
                            <p>
                                <ul>
                                    <li>
                                        If you do not wish to share your data with us, you can <strong> <a href="mailto:help@teddict.com " _blank>contact us</a></strong>, requesting to
                                        stop sharing your data.
                                    </li>
                                    <li>
                                        In the event you wish to opt-out of our data collection process by requesting us to stop accessing your data, you will no
                                        longer be able to access our Application and Services.
                                    </li>
                                </ul>
                            </p>


                            <h3>5. DATA STORAGE AND DELETATION</h3>
                            <p>
                                <ul>
                                    <li>
                                        Your personal data is stored with utmost security in accordance with the provision set out under the Personal Data Protection Act 2012.
                                    </li>
                                    <li>
                                        We store your data only as long as necessary to provide you with our Services and other legitimate business purposes including but not
                                        limited to, maintaining performance of the services or in compliance with the legal proceedings and requirements.
                                    </li>
                                    <li>
                                        We may retain your data as long as your account is active, once you have deleted, we will no longer store your information with us.
                                    </li>
                                    <li>
                                        We may retain some of your data even after the expiration of your term or deletion of your account, in the event including, but not
                                        limited to unresolved issues, law enforcement, fraud prevention, etc.
                                    </li>
                                    <li>
                                        You may request us, in writing to delete all your data from our Application at <strong> <a href="mailto:help@teddict.com " _blank>contact us</a></strong> all
                                        your data will be deleted within
                                        90 days of such request and will not be available with us anymore.
                                    </li>
                                </ul>
                            </p>


                            <h3>6. CHILDREN'S PRIVACY</h3>
                            <p>
                                This Application is not intended to be used by children under the age of 18 years without express permission of their parent or legal guardian.
                                We may process the personal data of children under the age of 18, provided the data has been provided with the consent and under the supervision
                                of the parents or legal guardians. We do not knowingly collect information from children and minors.
                            </p>


                            <h3 id="security">7. SECURITY MEASURES</h3>
                            <p>
                                We employ commercially reasonable security methods to prevent unauthorized access to the Application, to maintain data accuracy and to
                                ensure the correct use of the information we hold.
                                The registered user of the Application, the information of your account is protected through a password. We recommend that you do not
                                divulge your password to anyone. Our personnel will never ask for your
                                password through any unsolicited phone call or email.
                                We always try to protect your information that we hold, but we cannot guarantee the security of any information you transmit to us and
                                you do so at your own risk, as no data transmission over the internet or any wireless network can be guaranteed to be perfectly secure.
                            </p>

                            <h3>8. CHANGES TO THE PRIVACY POLICY</h3>
                            <p>
                                We may update this Privacy Policy from time to time, therefore you are requested to check the update periodically for any changes.
                                We will notify you of any changes by posting the new privacy policy on this page so that you are aware of what information we collect,
                                how we use it, and under what circumstances, if any we use and/or disclose it.
                            </p>


                            <h3>9. CONTACT US</h3>
                            <p>
                                If you have any questions about our Privacy Policy or information practices, please feel free to contact us
                                at {" "}
                                <a href="mailto:help@teddict.com">help@teddict.com</a>
                            </p>
                            <h3>10. BREACH NOTIFICATIONS </h3>
                            <p>
                                If any of your personal data has been breached, we would inform you and the respective data protection agencies
                                as to the accidents without any undue delay, if there are high risks of violation of your rights as data subject.
                                We would do our best to minimize any such risks.

                            </p>


                            <h3>11. Grievances</h3>
                            <p>
                                We hope that this Privacy Policy has helped you understand how your personal data will be dealt with at TEDDict.
                                We will take care of your personal data, so you can use our Services without feared of any privacy issues.
                                If you wish to contact us for any grievance regarding our Privacy Policy, you
                                can <a href="mailto:help@teddict.com " _blank>contact us</a> through email to express your grievance.

                                We will seek to redress your grievance without any undue delay and in accordance with the requirement of the applicable law.

                                <p style={{ fontWeight: 'bold' }}>
                                    TEDDict
                                </p>
                            </p>
                        </div>

                        <div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PrivacyPolicy;