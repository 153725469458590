import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import background from "../images/background.png"

function HeroHome() {
	return (
		<section className="relative">
			{/* Illustration behind hero content */}
			<div className="hero-image bg-right-bottom bg-cover flex absolute bottom-0 left-0 right-0 top-0 w-full bg-white" style={{ backgroundImage: `url(${background})`, opacity: 0.6 }}>
			</div>
			<div className="max-w-6xl mx-auto px-4 sm:px-6 relative">
				{/* Hero content */}
				<div className="pt-32 pb-12 md:pt-40 md:pb-20 ">
					{/* Section header */}
					<div className="text-center pb-12 md:pb-16 md:pt-10">
						<h1 className="text-5xl md:text-6xl font-bold leading-tighter tracking-tighter mb-4 text-gray-800" data-aos="zoom-y-out">
							Learn with 	<span className="bg-clip-text text-transparent bg-gradient-to-r from-red-800 to-blue-800 hover:from-pink-500 hover:to-yellow-500 dynamic-text">{""}</span><br></br> to live with purpose
						</h1>
						<div className="max-w-3xl mx-auto">
							<div
								className="max-w-md mx-auto sm:max-w-none sm:flex sm:justify-center mt-16"
								data-aos="zoom-y-out"
								data-aos-delay="300"
							>
								<div>
									<Link to="/about" className="btn text-white bg-gray-900 hover:bg-gray-800 w-full sm:w-auto sm:ml-4">
										Learn more
									</Link>
								</div>
							</div>
						</div>
					</div>

					{/* Hero image */}
					<div>
					</div>
				</div>
			</div>
			{/* </div> */}
		</section >
	);
}

export default HeroHome;
