import React from 'react';
import { Link } from 'react-router-dom';

function Members() {
    return (
        <section className="relative bg-gray-100">

            <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
                <div className="py-12 md:py-20">

                    {/* Section header */}
                    <div className="max-w-3xl mx-auto text-center pb-5 md:pb-5">
                        <h2 className="h2 mb-2">Team</h2>
                    </div>

                    {/* Items */}
                    <div class="grid gap-6 mb-8 md:grid-cols-2 lg:grid-cols-4 justify-items-center">
                        {/* Team Member component */}
                        <div class="w-sm flex flex-col items-center justify-center p-4 w-72">
                            <img class="inline-flex shadow-lg border border-gray-200 rounded-full overflow-hidden h-40 w-40" src={require('../images/people/alialam.jpg').default} alt="" />
                            <div class="mt-4 text-gray-900 text-center">
                                <h1 class="text-xl font-bold">Ali Alam</h1>
                                <p class="text-gray-600">External Projects Manager</p>
                            </div>
                        </div>
                        <div class="w-sm flex flex-col items-center justify-center p-4 w-72">
                            <img class="inline-flex shadow-lg border border-gray-200 rounded-full overflow-hidden h-40 w-40" src={require('../images/people/farhansoomro.jpg').default} alt="" />
                            <div class="mt-4 text-gray-900 text-center">
                                <h1 class="text-xl font-bold">Farhan Soomro</h1>
                                <p class="text-gray-600">Coaching and Projects</p>
                            </div>
                        </div>
                        <div class="w-sm flex flex-col items-center justify-center p-4 w-72">
                            <img class="inline-flex shadow-lg border border-gray-200 rounded-full overflow-hidden h-40 w-40" src={require('../images/people/asrarizwan.jpg').default} alt="" />
                            <div class="mt-4 text-gray-900 text-center">
                                <h1 class="text-xl font-bold">Asra Rizwan</h1>
                                <p class="text-gray-600">Communication Strategist</p>
                            </div>
                        </div>
                        <div class="w-sm flex flex-col items-center justify-center p-4 w-72">
                            <img class="inline-flex shadow-lg border border-gray-200 rounded-full overflow-hidden h-40 w-40" src={require('../images/people/shamoonirfan.jpg').default} alt="" />
                            <div class="mt-4 text-gray-900 text-center">
                                <h1 class="text-xl font-bold">Shamoon Irfan</h1>
                                <p class="text-gray-600">Event Manager Karachi</p>
                            </div>
                        </div>
                    </div>
                    <div className="relative flex justify-center mt-8">
                        <Link to="/team" className="btn text-white bg-gray-900 hover:bg-gray-800 w-full sm:w-auto sm:ml-4">Learn more </Link>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Members;
