import React from "react";
import Header from "../partials/Header";
import HeroTeam from "../partials/HeroTeam";
import TeamMembers from "../partials/TeamMembers";
import Footer from "../partials/Footer";

function Home() {
    return (
        <div className="flex flex-col min-h-screen overflow-hidden">
            {/*  Site header */}
            <Header />
            {/*  Page content */}
            <main className="flex-grow">
                <HeroTeam />
                <TeamMembers />
            </main>
            {/*  Site footer */}
            <Footer />
        </div>
    );
}

export default Home;
